import { useState, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Loading from "./Loading";

interface DocumentType {
  uri: string;
  fileType: string;
}

interface DocViewerProps {
  docUrl: string;
  documents: DocumentType[];
  setResumeUrl?: any;
  height: any;
}

const AtsDocViewer = ({
  docUrl,
  documents,
  setResumeUrl,
  height,
}: DocViewerProps) => {
  const [docViewerUrl, setDocViewerUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      const response = await fetch(docUrl);
      setLoading(response.ok);
      var encodedUrl = encodeURIComponent(docUrl);
      // google docs viewer
      // const src = "https://docs.google.com/viewerng/viewer?url=" + encodedUrl + "&embedded=true";
      // office doc viewer
      const src =
        "https://view.officeapps.live.com/op/embed.aspx?src=" + encodedUrl;

      response.blob().then((blob) => {
        let url = URL.createObjectURL(blob);
        setResumeUrl(url);
      });
      setDocViewerUrl(src);
    };
    loadPdf();
  }, [docUrl]);

  return (
    <>
      {loading && docViewerUrl !== "" ? (
        <>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={documents}
            style={{ width: "100%", height: height }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default AtsDocViewer;
