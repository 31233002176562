import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api.slice";
import { appError } from "./app.slice";

export const resumeSlice = createSlice({
  name: "resume",
  initialState: {
    resumeData: {},
  },
  reducers: {
    setResumeData: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { setResumeData } = resumeSlice.actions;
export default resumeSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const resumeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetUrl: builder.mutation({
      query: (data) => ({
        url: `/careers/resume-public`,
        method: "POST",
        credentials: "include",
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setResumeData({ resumeData: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useGetUrlMutation } = resumeApiSlice;
