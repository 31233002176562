import React from "react";
import { Route, Routes } from "react-router";
import Resume from "./Resume";
const ResumePage = () => {
  return (
    <Routes>
      <Route path="/:id" element={<Resume />}></Route>
    </Routes>
  );
};

export default ResumePage;
