const CryptoJS = require("crypto-js");
const key = process.env.REACT_APP_SECRET;
const SHA256 = require("crypto-js/sha256");

const encrypt = (text: any) => {
  return CryptoJS.AES.encrypt(text.toString(), key).toString();
};

const decrypt = (text: any) => {
  const base64 = text.replace(/-/g, "+").replace(/_/g, "/");
  const bytes = CryptoJS.AES.decrypt(base64, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export { encrypt, decrypt };
