import { Box, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Box
      style={{
        width: "80%",
        maxWidth: "900px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Box padding={10} boxShadow="lg" bg="white" mt={10}>
        <SkeletonCircle size="100" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="100px" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="100px" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="100px" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="100px" />
        <Skeleton mt={5} height="10px" />
      </Box>
    </Box>
  );
};
export default Loading;
