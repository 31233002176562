import React from "react";
import { Box, Input, Link } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Loading from "./Loading";
import { useParams } from "react-router";
import { decrypt, encrypt } from "utils/encryption";
import { useGetUrlMutation } from "store/resume.slice";
import StreamAttachmentComponent from "./Stream";
import { useSelector } from "react-redux";

const Resume = () => {
  const [reqGet, resGet] = useGetUrlMutation();
  const { resumeData } = useSelector((state: any) => state.resume);

  const param = useParams();
  useEffect(() => {
    reqGet({ id: decrypt(param.id) });
  }, [param.id]);

  return (
    <Box style={{ textAlign: "center", padding: "20px" }}>
      {resGet.isLoading ? (
        <Loading />
      ) : (
        <StreamAttachmentComponent
          filename={resumeData?.file_name}
          fileUrl={resumeData?.url}
          pdfHeight={"100vh"}
          docHeight={"100vh"}
        />
      )}
    </Box>
  );
};

export default Resume;
