import React from "react";
import { PageRouteProps } from "types";
import ResumePage from "components/app/Resume";

const pageRoute: PageRouteProps[] = [
  {
    name: "Resume",
    component: <ResumePage />,
    path: "/*",
  },
];

export default pageRoute;
